<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <vx-card
      ref="filterCard"
      v-bind:title="$t('Filters')"
      class="user-list-filters mb-1"
      collapse-action
    >
      <div class="vx-row">
        <!-- DateFrom -->
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("Date From") }}</label>
          <datepicker
            placeholder="from"
            v-model="ProfiteSearch.DateFrom"
            class="vx-col w-full"
            name="from"
          ></datepicker>
        </div>
        <!-- DateTo -->
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("Date To") }}</label>
          <datepicker
            placeholder="from"
            v-model="ProfiteSearch.DateTo"
            class="w-full"
            name="to"
          ></datepicker>
        </div>
        <!-- SeviceTypeID -->
        <div
          v-show="ProfiteSearch.IsPrivate != true"
          class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2"
        >
          <label class="text-sm opacity-75">{{ $t("Sevice Type") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="ProfiteSearch.SeviceTypeID"
            :options="SeviceType"
            :reduce="(ID) => ID.ID"
            @input="ChangeSearchFilter"
          />
        </div>
        <!-- PatientID -->
        <div
          v-show="ProfiteSearch.IsPrivate != true"
          class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2"
        >
          <label class="text-sm opacity-75">{{ $t("Patients") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="ProfiteSearch.PatientID"
            :options="Patients"
            :reduce="(ID) => ID.ID"
          />
        </div>
        <!-- DoctorID -->
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("doctors") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="ProfiteSearch.DoctorID"
            :options="doctors"
            :reduce="(ID) => ID.ID"
            :disabled="DoctorShow"
          />
        </div>
        <!-- HospitalID -->
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <label class="vs-input--label">{{ $t("Hospitals") }} </label>
          <v-select
            required
            label="NameEN"
            class="w-full"
            v-model="ProfiteSearch.HospitalID"
            :options="Hospitals"
            :reduce="(ID) => ID.ID"
            :disabled="HospitalShow"
          />
        </div>
        <!-- StatusID -->
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("Status") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="ProfiteSearch.StatusID"
            :options="Status"
            :reduce="(ID) => ID.ID"
          />
        </div>
        <!-- PaymentStatusID -->
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("Payment Status") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="ProfiteSearch.PaymentStatusID"
            :options="PaymentStatuses"
            :reduce="(ID) => ID.ID"
          />
        </div>

        <div class="vx-col w-full">
          <div class="vx-row">
            <vs-button
              style="margin: 10px"
              class="my-3"
              @click="ProfitSearch"
              v-scroll-to="'#Scroll'"
              >{{ $t("Search") }}</vs-button
            >
            <vs-button
              style="margin-left: 10px"
              class="my-3"
              @click="resetData"
              >{{ $t("Reset") }}</vs-button
            >
          </div>
        </div>
         <div class="vx-col w-full">

           <div class="float-right">
            <vue-excel-xlsx
              style="width: 12rem; height: 3rem; font-size: 1.5rem"
              :data="ProfitData"
              :columns="columns"
              :filename="'ProfitReport'"
              :sheetname="'ProfitReport'"
            >
              Export Exel
            </vue-excel-xlsx>

          </div>
        </div>
      </div>
    </vx-card>

    <vs-table
      id="Scroll"
      ref="table"
      multiple
      v-model="selected"
      pagination
      :max-items="itemsPerPage"
      search
      :data="ProfitData"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
          <div
            class="
              p-4
              border border-solid
              d-theme-border-grey-light
              rounded-full
              d-theme-dark-bg
              cursor-pointer
              flex
              items-center
              justify-between
              font-medium
            "
          >
            <span class="mr-2"
              >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{
                ProfitData.length - currentPage * itemsPerPage > 0
                  ? currentPage * itemsPerPage
                  : ProfitData.length
              }}
              of {{ ProfitData.length }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="Doctor">{{ $t("Inovice No") }}</vs-th>
        <vs-th sort-key="Doctor">{{ $t("Booking Reference ") }}</vs-th>
        <vs-th sort-key="Doctor">{{ $t("Booking Status") }}</vs-th>
        <vs-th sort-key="Day">{{ $t("Sevice Date ") }}</vs-th>
        <vs-th sort-key="Day">{{ $t("Sevice Type ") }}</vs-th>
        <vs-th sort-key="Day">{{ $t("Patient") }}</vs-th>
        <vs-th sort-key="Day">{{ $t("Doctor/Hospital") }}</vs-th>
          <vs-th>{{ $t("Patient's Country") }}</vs-th>
        <vs-th>{{ $t("Doctor/Hospital's Country") }}</vs-th>

        <vs-th sort-key="Day">{{ $t("Net Price") }}</vs-th>
        <vs-th sort-key="Day">{{ $t("Currency") }}</vs-th>
        <vs-th sort-key="Day">{{ $t("Markup Amount") }}</vs-th>
        <vs-th sort-key="Day">{{ $t("Currency") }}</vs-th>

        <vs-th sort-key="Day">{{ $t("Conversion Rate") }}</vs-th>
        <vs-th sort-key="Day">{{ $t("Selling Price") }}</vs-th>
        <vs-th sort-key="Day">{{ $t("Currency") }}</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="product-name font-medium truncate">
                INV-{{ tr.ReferenceNumber }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.ReferenceNumber }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.StatusName }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.Date }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.ServiceName }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.PatientName }}
              </p>
            </vs-td>

            <vs-td>
              <p v-if="tr.DoctorName" class="product-name font-medium truncate">
                <!-- {{ tr.DoctorSession.DayUTC.split("T")[0] }} -->
                {{ tr.DoctorName }}
              </p>
              <p
                v-if="tr.HospitalName"
                class="product-name font-medium truncate"
              >
                <!-- {{ tr.DoctorSession.DayUTC.split("T")[0] }} -->
                {{ tr.HospitalName }}
              </p>
            </vs-td>
           <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.PatientCountry }}
              </p>
            </vs-td>

            <vs-td>
              <p v-if="tr.DoctorHospitalCountry" class="product-name font-medium truncate">
                <!-- {{ tr.DoctorSession.DayUTC.split("T")[0] }} -->
                {{ tr.DoctorHospitalCountry }}
              </p>

            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.NetPrice }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.NetPriceCurrencyName }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.MarkupFees }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.MarkupFeesCurrencyName }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.Rate }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.SellingPrice }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">not known</p>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import modulePatient from "@/store/Patient/modulePatient.js";
import moduleReservation from "@/store/reservation/ReservationSession/moduleReservation.js";
import moduleHospital from "@/store/Hospital/moduleHospital.js";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import VueExcelXlsx from "vue-excel-xlsx";
import Vue from "vue";
Vue.use(VueExcelXlsx);
export default {
  components: {
    Datepicker,
  },
  data() {
    return {
      selected: [],
      search: {},
      // Patients: [],
        columns: [
        {
          label: "Booking Reference ",
          field: "ReferenceNumber",
        },
        {
          label: "Booking Status",
          field: "StatusName",
        },
        {
          label: "Sevice Date",
          field: "Date",
        },
        {
          label: "Sevice Type",
          field: "ServiceName",
        },
        {
          label: "Patient",
          field: "PatientName",
        },
        {
          label: "Doctor",
          field: "DoctorName",
        },

        {
          label: "Hospital",
          field: "HospitalName",
        },

        {
          label: "Net Price",
          field: "NetPrice",
        },

        {
          label: "Currency",
          field: "NetPriceCurrencyName",
        },
        {
          label: "Markup Fees",
          field: "MarkupFees",
        },

        {
          label: "Markup Fees Currency",
          field: "MarkupFeesCurrencyName",
        },
      ],
      Status: [],
      PaymentStatuses: [],
      itemsPerPage: 10,
      isMounted: false,
      HospitalShow: false,
      DoctorShow: false,
      rowDataForDelete: {},
      SeviceType: [
        { ID: 1, Name: "Online Appointment" },
        { ID: 2, Name: "Surgery" },
        { ID: 3, Name: "Medical Package" },
      ],
      activeConfirm: false,
    };
  },
  computed: {
    ProfiteSearch() {
      debugger;
      return this.$store.state.ReservationList.ProfiteSearchObj;
    },
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    ProfitData() {
      return this.$store.state.patientList.ProfitData;
    },
    doctors() {
      return this.$store.state.DoctorList.doctors;
    },
    Patients() {
      return this.$store.state.patientList.patients;
    },
    Hospitals() {
      return this.$store.state.HospitalList.Hospitals;
    },
  },

  methods: {
    resetData() {
      this.ProfiteSearch = {};

      this.ProfiteSearch.DateFrom =
        new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-1";

      this.ProfiteSearch.DateTo =
        new Date().getFullYear() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
        ).getDate();
        this.ProfiteSearch.SeviceTypeID=null;
        this.ProfiteSearch.PaymentStatusID=null;
        this.ProfiteSearch.HospitalID=null;
        this.ProfiteSearch.DoctorID=null;
        this.ProfiteSearch.StatusID=1;
        this.ProfitSearch();
        this.ProfitSearch();
    },
    ProfitSearch() {
      this.$store.dispatch("patientList/GetProfitDataReport", this.ProfiteSearch);
    },

    GetPatient() {
      this.$store
        .dispatch("patientList/SearchPatients", {})
        .then(() => {
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    GetAllStatuses() {
      this.$store
        .dispatch("ReservationList/GetAllStatuses")
        .then((res) => {
          this.Status = res.data.Data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    GetAllPaymentStatuses() {
      this.$store
        .dispatch("ReservationList/GetAllPaymentStatuses")
        .then((res) => {
          this.PaymentStatuses = res.data.Data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    ChangeSearchFilter() {
      debugger;
      if (this.ProfiteSearch.SeviceTypeID == 2 || this.SeviceTypeID == 3) {
        this.DoctorShow = true;
        this.HospitalShow = false;
      }

      if (this.ProfiteSearch.SeviceTypeID == 1) {
        this.HospitalShow = true;
        this.DoctorShow = false;
      }
      if (
        this.ProfiteSearch.SeviceTypeID == 0 ||
        this.ProfiteSearch.SeviceTypeID == null ||
        this.ProfiteSearch.SeviceTypeID == undefined
      ) {
        this.DoctorShow = false;
        this.HospitalShow = false;
      }
    },
  },

  created() {
    if (!moduleReservation.isRegistered) {
      this.$store.registerModule("ReservationList", moduleReservation);
      moduleReservation.isRegistered = true;
    }
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }

    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    if (!moduleHospital.isRegistered) {
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }

    this.GetPatient();
    this.GetAllStatuses();
    this.GetAllPaymentStatuses();


    this.$store.dispatch("DoctorList/GetAllDoctors", {});
    this.$store.dispatch("HospitalList/GetAllHospitals", {});
    this.resetData();
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
